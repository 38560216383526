import React from "react";
function Home() {
  return (
    <div className="page-heading">
      <h1>Home</h1>
    </div>
  );
}

export default Home;
