import React from "react";
import {BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Navbar from "./Navbar/Navbar";
import Home from "./pages/Home";
import ContactUs from "./pages/ContactUs";
import Products from "./pages/Products";
import Services from "./pages/Services";
import AboutUs from "./pages/AboutUs";
import "./App.css";

function App() {
  return (
    <div className="container">
      <Router>
        <Navbar />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/products" exact component={Products} />
          <Route path="/services" exact component={Services} />
          <Route path="/aboutus" exact component={AboutUs} />
          <Route path="/contactus" exact component={ContactUs} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
//https://github.com/briancodex/react-navbar-v1/tree/master/src/components
