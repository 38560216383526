import React from "react";
function Products() {
  return (
    <div className="page-heading">
      <h1>Products</h1>
    </div>
  );
}

export default Products;
