export const MenuList = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "Products",
    url: "/products",
  },
  {
    title: "Services",
    url: "/services",
  },
  {
    title: "About Us",
    url: "/aboutus",
  },
  {
    title: "Contact Us",
    url: "/contactus",
  },
];
