import React from "react";
function Services() {
  return (
    <div className="page-heading">
      <h1>Services</h1>
    </div>
  );
}

export default Services;
