import React from "react";
function ContactUs() {
  return (
    <div className="page-heading">
      <h1>Contact Us</h1>
    </div>
  );
}

export default ContactUs;
